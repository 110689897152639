import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';
import SelectField from '../components/utilities/SelectField';

import './swag.scss';

const states = [
    {
        label:'Alaska',
        value:'Alaska'
    },
    {
        label:'',
        value:''
    },
    {
        label:'Alabama',
        value:'Alabama'
    },
    {
        label:'Arkansas',
        value:'Arkansas'
    },
    {
        label:'Arizona',
        value:'Arizona'
    },
    {
        label:'California',
        value:'California'
    },
    {
        label:'Colorado',
        value:'Colorado'
    },
    {
        label:'District of Columbia',
        value:'District of Columbia'
    },
    {
        label:'Delaware',
        value:'Delaware'
    },
    {
        label:'Florida',
        value:'Florida'
    },
    {
        label:'Georgia',
        value:'Georgia'
    },
    {
        label:'Hawaii',
        value:'Hawaii'
    },
    {
        label:'Iowa',
        value:'Iowa'
    },
    {
        label:'Idaho',
        value:'Idaho'
    },
    {
        label:'Illinois',
        value:'Illinois'
    },
    {
        label:'Indiana',
        value:'Indiana'
    },
    {
        label:'Kansas',
        value:'Kansas'
    },
    {
        label:'Kentucky',
        value:'Kentucky'
    },
    {
        label:'Louisiana',
        value:'Louisiana'
    },
    {
        label:'Massachusetts',
        value:'Massachusetts'
    },
    {
        label:'Maryland',
        value:'Maryland'
    },
    {
        label:'Maine',
        value:'Maine'
    },
    {
        label:'Michigan',
        value:'Michigan'
    },
    {
        label:'Minnesota',
        value:'Minnesota'
    },
    {
        label:'Missouri',
        value:'Missouri'
    },
    {
        label:'Mississippi',
        value:'Mississippi'
    },
    {
        label:'Montana',
        value:'Montana'
    },
    {
        label:'North Carolina',
        value:'North Carolina'
    },
    {
        label:'North Dakota',
        value:'North Dakota'
    },
    {
        label:'Nebraska',
        value:'Nebraska'
    },
    {
        label:'New Hampshire',
        value:'New Hampshire'
    },
    {
        label:'New Jersey',
        value:'New Jersey'
    },
    {
        label:'New Mexico',
        value:'New Mexico'
    },
    {
        label:'Nevada',
        value:'Nevada'
    },
    {
        label:'New York',
        value:'New York'
    },
    {
        label:'Ohio',
        value:'Ohio'
    },
    {
        label:'Oklahoma',
        value:'Oklahoma'
    },
    {
        label:'Oregon',
        value:'Oregon'
    },
    {
        label:'Pennsylvania',
        value:'Pennsylvania'
    },
    {
        label:'Puerto Rico',
        value:'Puerto Rico'
    },
    {
        label:'Rhode Island',
        value:'Rhode Island'
    },
    {
        label:'South Carolina',
        value:'South Carolina'
    },
    {
        label:'South Dakota',
        value:'South Dakota'
    },
    {
        label:'Tennessee',
        value:'Tennessee'
    },
    {
        label:'Texas',
        value:'Texas'
    },
    {
        label:'Utah',
        value:'Utah'
    },
    {
        label:'Virginia',
        value:'Virginia'
    },
    {
        label:'Vermont',
        value:'Vermont'
    },
    {
        label:'Washington',
        value:'Washington'
    },
    {
        label:'Wisconsin',
        value:'Wisconsin'
    },
    {
        label:'West Virginia',
        value:'West Virginia'
    },
    {
        label:'Wyoming',
        value:'Wyoming'
    }
];

const shirtSizes = [
    {
        label:'Small',
        value:'Small'
    },
    {
        label:'Medium',
        value:'Medium'
    },
    {
        label:'Large',
        value:'Large'
    },
    {
        label:'XL',
        value:'XL'
    }
];

class SWAGForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',
            contactCompany: '',
            contactStreet: '',
            contactCity: '',
            contactState: '',
            contactZip: '',
            contactCell: '',
            contactTshirtsize: ''
        };

        this.submit = this.submit.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
        this.streetChange = this.streetChange.bind(this);
        this.cityChange = this.cityChange.bind(this);
        this.stateChange = this.stateChange.bind(this);
        this.zipChange = this.zipChange.bind(this);
        this.cellChange = this.cellChange.bind(this);
        this.tshirtsizeChange = this.tshirtsizeChange.bind(this);
        
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#contentForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',
            contactCompany: '',
            contactStreet: '',
            contactCity: '',
            contactState: '',
            contactZip: '',
            contactCell: '',
            contactTshirtsize: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#contentForm').hide();
                $('.form-wrapper .thankyou').show();
                // window.open(
                //     'https://mm-stats.com/bu0py',
                //     '_blank' // <- This is what makes it open in a new tab.
                // );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    firstNameChange(e){
        this.setState({contactFirstName: e.target.value})
    }

    lastNameChange(e){
        this.setState({contactLastName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    streetChange(e){
        this.setState({contactStreet: e.target.value})
    }

    cityChange(e){
        this.setState({contactCity: e.target.value})
    }

    stateChange(e){
        this.setState({contactState: e.target.value})
    }

    zipChange(e){
        this.setState({contactZip: e.target.value})
    }

    cellChange(e){
        this.setState({contactCell: e.target.value})
    }

    tshirtsizeChange(e){
        this.setState({contactTshirtsize: e.target.value})
    }

    render() {
        return (
            <form id="contentForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"firstnamecontent"} valChange={this.firstNameChange} val={this.state.contactFirstName} type={"text"}  name={"firstname"} label={"First Name"}/>
                <FormField fieldID={"lastnamecontent"} valChange={this.lastNameChange} val={this.state.contactLastName} type={"text"}  name={"lastname"} label={"Last Name"}/>
                <FormField fieldID={"emailcontent"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companycontent"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <p><b>Mailing Address:</b></p>
                <FormField fieldID={"streetcontent"}  valChange={this.streetChange} val={this.state.contactStreet} type={"text"}  name={"street"} label={"Street"}/>
                <div className="row">
                    <div className="col-6">
                        <FormField fieldID={"citycontent"}  valChange={this.cityChange} val={this.state.contactCity} type={"text"}  name={"city"} label={"City"}/>
                    </div>
                    <div className="col-3">
                        <SelectField fieldID={"statecontent"} valChange={this.stateChange} val={this.state.contactState} label={"State *"} name={"state"} values={states}/>
                    </div>
                    <div className="col-3">
                        <FormField fieldID={"zipcontent"}  valChange={this.zipChange} val={this.state.contactZip} type={"text"}  name={"zip"} label={"Zip"}/>
                    </div>
                </div>
                <FormField fieldID={"cellcontent"}  valChange={this.cellChange} val={this.state.contactCell} type={"tel"} name={"cell"} label={"Cell *"}/>
                <p><b>Unisex T-Shirt Size:</b></p>
                <SelectField fieldID={"tshirtsizecontent"} valChange={this.tshirtsizeChange} val={this.state.contactTshirtsize} label={"Unisex T-Shirt Size *"} name={"tshirtsize"} values={shirtSizes}/>
                <button type="submit" className="btn btn-white">SUBMIT</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/swag/" />
                    <input type="hidden" name="auth" value="30d29a11-4a22-4bdf-a48d-fd6165218a98" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
                <p className="text-right smaller">* All fields required</p>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} SWAG-template`}>
            <div className="lp-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-12 hero-title">
                            <h1>Welcome to<br />Merit Mile SWAG!<span className="bar"></span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h1 style={{marginBottom: '1em'}}>Get Yours Today</h1>
                            <p>Thanks for stopping by! Since year one in 2007, we've been creating fun Merit Mile swag annually for staff, clients, friends, and family. We hope you like it--and we invite you to get yours today! Please check back every now and again to see what new items we may have in inventory.</p>
                            <img src="https://www.meritmile.com/uploads/2022/11/swag-grid.jpg" alt="Merit Mile SWAG" className="img-responsive left" style={{marginTop: '3em'}} />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="content-form">
                                <div className="form-wrapper">
                                    <h2 className="text-center">To get your swag in the mail, please complete and submit the form below today!</h2>
                                    <SWAGForm />
                                    <div className="thankyou">
                                        <h4>Thank You</h4>
                                        <p>Your submission has been received!</p>
                                        <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p style={{ marginTop: '3em'}}>If you'd like to say thanks, we encourage you to provide us with a <a href="https://www.google.com/maps/place/Merit+Mile/@26.3518063,-80.0880388,17z/data=!3m1!4b1!4m5!3m4!1s0x88d8e1f0ec0765a9:0x496e6300fab8aea0!8m2!3d26.3518017!4d-80.0858401" target="_blank" rel="noopner noreferrer"><u>Google Review</u></a> and also tag us on social media @MeritMile using the #MileStyle hashtag too!</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src="https://www.meritmile.com/uploads/2022/11/swag-mugs.jpg" alt="Merit Mile SWAG" className="img-responsive right" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=2022%20SWAG%20Form%20Submission%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </LayoutLPOne>
)